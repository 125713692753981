<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">WMS</p>
        </div>
        <input type="file" @change="handleFileUpload" />
        <el-button type="primary" @click="downloadtxt()">下载</el-button>
        <div v-if="excelData">
        <!-- <table>
            <tr v-for="(row, rowIndex) in excelData" :key="`row-${rowIndex}`">
            <td v-for="(cell, cellIndex) in row" :key="`cell-${cellIndex}`">{{ cell }}</td>
            </tr>
        </table> -->
        </div>

    </div>
</template>
<script>
import * as XLSX from 'xlsx';
export default {
  name: "Task",
  data() {
   
    return {
        excelData: null,
        zbstr:"",

    };
  },
  mounted() {

  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log(this.excelData)
        this.setZBStr()
      };
      reader.readAsArrayBuffer(file);
    },
    setZBStr(){
        var str=""
        var jList=[]
        for(var i=0;i<this.excelData.length;i++){
            var d=this.excelData[i]
            if(d.length>=4 && typeof d[1] ==='string' && d[1].indexOf("J")!= -1){
                jList.push(d)
            }
        }
        console.log(jList)
        var arrList=[]
        var arrItem=[]
        for(var j=0;j<jList.length;j++){
            var r=jList[j]
            var p=jList[j+1]
            if((p[0] - r[0]) == 1 ){
                arrItem.push(r)
            }else if(p[0] == r[0]){
                continue; 
            }else{
                arrItem.push(r)
                if(arrItem.length>1){
                    if((j+1) == jList.length-1){
                        arrItem.push(p)
                        arrList.push(arrItem)
                        break;
                    }else{
                        arrList.push(arrItem)
                    }
                    
                }
                arrItem=[]
            }
        }


        for(var k=0;k<arrList.length;k++){
            var m=arrList[k]
            if(k>0){
                str+="Z"+'\n'
            }
            for(var o=0;o<m.length;o++){
                var dian=m[o]
                str+=dian[1]+","+dian[2]+","+dian[3]+'\n'
            }
        }
        this.zbstr=str
    },
    downloadtxt(){
        this.downloadStringAsTextFile(this.zbstr,'zb.txt')
    },
    downloadStringAsTextFile(textContent, fileName) {
            var blob = new Blob([textContent], { type: 'text/plain' });
            var url = URL.createObjectURL(blob);
            var downloadLink = document.createElement("a");
            
            downloadLink.href = url;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
    
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
    },
    getTxtFileByStr(textContent,fileName){
        var blob = new Blob([textContent], { type: 'text/plain' });
        return new File([blob], fileName, {
            type: 'text/plain',
            lastModified: new Date().getTime(), // 或者使用其他适当的时间戳
        });
    }

    
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
</style>