<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">落宗列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <!-- <el-input v-model="name" placeholder="任务名称" class="ics" ></el-input> -->
                <el-input v-model="zdmc" placeholder="宗地名称" class="ics" ></el-input>
                <el-input v-model="sbdcdyh" placeholder="不动产单元号" class="ics" ></el-input>
                <el-select v-model="fejdw" placeholder="二级单位"  filterable  clearable class="ics">
                    <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-select v-model="zdlx" placeholder="土地用途"  filterable  clearable class="ics">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="zslx" placeholder="证书类型"  filterable  clearable class="ics">
                    <el-option v-for="item in zslxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-input v-model="zsbh" placeholder="证书编号" class="ics" ></el-input>
                <el-cascader v-model="regions"  :options="options" :props="cityPropsB"  placeholder="省市区"  class="ics" clearable  filterable> </el-cascader>

                <el-select v-model="tjr" placeholder="提交人"  filterable  clearable class="ics" v-show="sysType=='2'">
                        <el-option v-for="item in tjrList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="zydwid" placeholder="作业单位"  filterable  clearable class="ics" v-show="sysType=='5'">
                        <el-option v-for="item in zydwList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <!-- <el-select v-model="sfyz" placeholder="是否有证（批复）"  filterable  clearable class="ics">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select> -->
                <!-- <el-select v-model="mjsfyy" placeholder="面积是否一样"  filterable  clearable class="ics">
                        <el-option label="是" value="0" ></el-option>
                        <el-option label="否" value="1" ></el-option>
                </el-select> -->
                <el-button type="primary" @click="searchPost">查询</el-button>

                <el-dropdown class="fr" v-if="sysType=='2' || sysType=='5'">
                    <el-button type="warning">
                        表格导出<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="exportPost" >落宗详情表</el-dropdown-item>
                        <el-dropdown-item @click.native="exportPostB" v-if="sysType=='5'">结算清单表</el-dropdown-item>
                        <el-dropdown-item @click.native="exportPostC" v-if="sysType=='5'">难度等级结算表</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="LANDNAME" label="宗地名称"></el-table-column>
                    <el-table-column prop="LZLandTypeName" label="土地用途"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="REGION" label="省市区"></el-table-column>
                    <el-table-column prop="TOWN" label="镇(乡)"></el-table-column>
                    <el-table-column prop="VILLAGE" label="村"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号"></el-table-column>
                    <!-- <el-table-column prop="ISHAVECERTIFICATE" label="有无证（批复）">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISHAVECERTIFICATE== 1">有</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="OLDBHTYPE" label="证书类型"></el-table-column>
                    <el-table-column prop="OLDBH" label="证书编号">
                        <template slot-scope="scope">
                            <span v-if="scope.row.OLDBH">{{scope.row.OLDBH}}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LZMJ" label="落宗面积"></el-table-column>
                    <el-table-column prop="OLDMJ" label="原面积">
                        <template slot-scope="scope">
                            <span v-if="scope.row.OLDMJ">{{scope.row.OLDMJ}}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OLDMJ" label="差额面积">
                            <template slot-scope="scope">
                                <span v-if="scope.row.OLDMJ">{{Math.abs((scope.row.LZMJ - scope.row.OLDMJ).toFixed(2))}}</span>
                                <span v-else></span>
                            </template>
                        </el-table-column>
                    <el-table-column prop="CHANGEINFO" label="面积变化原因">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CHANGEINFO">{{scope.row.CHANGEINFO}}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                    <el-table-column prop="WorkUserName" label="提交人"></el-table-column>
                    <el-table-column prop="UpdateTime" label="提交时间"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="sysType=='3'&&scope.row.UserCanUpdate==1">编辑</el-button>
                            <el-button @click="delFall(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="sysType=='3'&&scope.row.UserCanDelete==1">删除</el-button>
                            <el-button @click="taskPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.UserCanDelete!=1">查看任务</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="数据编辑" :visible.sync="fallModal" width="800px" :close-on-click-modal="false" top="30px">
            <el-form :model="fallInfo" status-icon :rules="fallrules"  ref="fForm" label-width="200px" class="demo-uForm">
                <el-form-item label="宗地名称" prop="LANDNAME">
                    <el-input v-model="fallInfo.LANDNAME" autocomplete="off" placeholder="请输入宗地名称" ></el-input>
                </el-form-item>
                <el-form-item label="土地用途" prop="lzlandtypesnowid">
                    <el-select v-model="fallInfo.lzlandtypesnowid" placeholder="请选择土地用途"  filterable  style="width:100%;">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="EJDW">
                    <el-select v-model="fallInfo.EJDW" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="省市区" prop="regions">
                    <el-cascader v-model="fallInfo.regions"  :options="options" :props="cityProps"  placeholder="请选择省市区"  style="width:100%" filterable></el-cascader>
                </el-form-item>
                <el-form-item label="镇(乡)">
                    <el-input v-model="fallInfo.TOWN" autocomplete="off" placeholder="请输入镇(乡)"></el-input>
                </el-form-item>
                <el-form-item label="村">
                    <el-input v-model="fallInfo.VILLAGE" autocomplete="off" placeholder="请输入村"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fallInfo.bdcdyh" autocomplete="off" placeholder="请输入不动产单元号（格式为28位的数字和字母）" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="有无证（批复）" prop="ishavecertificate" >
                    <el-radio v-model="fallInfo.ishavecertificate" label="1">有证（批复）</el-radio>
                    <el-radio v-model="fallInfo.ishavecertificate" label="0">无证（批复）</el-radio>
                </el-form-item> -->
                <el-form-item label="证书类型" prop="zstypesnowid">
                    <el-select v-model="fallInfo.zstypesnowid" placeholder="请选择证书类型"  filterable  style="width:100%;" @change="zslxChange">
                        <el-option v-for="item in zslxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书编号" prop="oldbh" v-show="notRight!=1">
                    <el-input v-model="fallInfo.oldbh" autocomplete="off" placeholder="请输入证书编号"></el-input>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="lzmj" >
                    <el-input v-model="fallInfo.lzmj" autocomplete="off" placeholder="请输入落宗面积（只保留两位小数）"></el-input>
                </el-form-item>
                <el-form-item label="原面积（平方米）" prop="oldmj">
                    <el-input v-model="fallInfo.oldmj" autocomplete="off" placeholder="请输入原面积，有证时必填"></el-input>
                </el-form-item>
                <el-form-item label="面积变化原因" prop="changeinfo">
                    <el-input v-model="fallInfo.changeinfo" autocomplete="off" placeholder="请输入面积变化原因,一致时不必填"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFallForm()">提交</el-button>
                    <el-button @click="fallModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="已绑定任务" :visible.sync="FallBindModal" width="1300px" :close-on-click-modal="false" top="50px">
            <el-table :data="FpostList" stripe style="width: 100%" :height="fallTableHeight"> 
                    <el-table-column prop="TASKNAME" label="任务名称"></el-table-column>
                    <el-table-column prop="LANDNAME" label="宗地项目名称"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                    <el-table-column prop="WorkUserName" label="作业人员"></el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button @click="unbindPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="sysType=='3' && scope.row.WORKUSERSNOWID ==zyryid">解绑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="FPageIndex" :page-size="FPageSize" :total="FTotal" class="pg" @current-change="FPGChange"></el-pagination>
                <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
        <el-dialog title="导出落宗明细表" :visible.sync="exportModal" width="800px" :close-on-click-modal="false">
            <el-form  status-icon  label-width="200px" class="demo-uForm">
                <el-form-item label="作业单位" v-if="sysType=='5'">
                    <el-checkbox-group v-model="checkUnits">
                        <el-checkbox v-for="item in zydwList" :label="item.SNOWID" :key="item.SNOWID">{{ item.DISPLAYNAME }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="作业单位" v-if="sysType=='2'">
                   <p>{{unitname}}</p>
                </el-form-item>
                <el-form-item label="二级单位">
                    <el-select v-model="exportEjdw" placeholder="请选择二级单位（选填）"  filterable clearable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="土地用途">
                    <el-select v-model="exportTdyt" placeholder="请选择土地用途（选填）"  filterable clearable style="width:100%;">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书类型">
                    <el-select v-model="exportZslx" placeholder="请选择证书类型（选填）"  filterable clearable style="width:100%;">
                        <el-option label="土地证" value="0" ></el-option>
                        <el-option label="批复" value="1" ></el-option>
                        <el-option label="无权源" value="2" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>  
                    <el-button :type="exportStatus?'danger':'warning'" @click="submitExport">{{exportStatus?'导出中，点击停止':'确定导出'}}</el-button>
                    <el-button @click="exportModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="导出结算清单表" :visible.sync="exportModalB" width="800px" :close-on-click-modal="false">
            <el-form  status-icon  label-width="200px" class="demo-uForm">
                <el-form-item label="作业单位">
                    <el-checkbox-group v-model="checkUnitsB">
                        <el-checkbox v-for="item in zydwList" :label="item.SNOWID" :key="item.SNOWID">{{ item.DISPLAYNAME }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            
                <el-form-item>  
                    <el-button :type="exportStatusB?'danger':'warning'" @click="submitExportB">{{exportStatusB?'导出中，点击停止':'确定导出'}}</el-button>
                    <el-button @click="exportModalB = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="导出难度等级表" :visible.sync="exportModalC" width="800px" :close-on-click-modal="false">
            <el-form  status-icon  label-width="200px" class="demo-uForm">
                <el-form-item label="作业单位">
                    <el-checkbox-group v-model="checkUnitsC">
                        <el-checkbox v-for="item in zydwList" :label="item.SNOWID" :key="item.SNOWID">{{ item.DISPLAYNAME }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            
                <el-form-item>  
                    <el-button :type="exportStatusC?'danger':'warning'" @click="submitExportC">{{exportStatusC?'导出中，点击停止':'确定导出'}}</el-button>
                    <el-button @click="exportModalC = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
export default {
  name: "Fall",
  data() {
    var LANDNAME = (rule, value, callback) => {
        if (value === "" || value == null) {
        callback(new Error("请输入宗地名称"));
      } else {
        callback();
      }
    };

    var EJDW = (rule, value, callback) => {
        if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var zstypesnowid = (rule, value, callback) => {
        if (value === "" || value == null) {
        callback(new Error("请选择证书类型"));
      } else {
        callback();
      }
    };

    var regions = (rule, value, callback) => {
      if (value.length!= 3 || value == null) {
        callback(new Error("请选择正确的行政区"));
      } else {
        callback();
      }
    };
    
    var lzmj=(rule, value, callback) => {
        if (value === "" || value == null) {
            callback(new Error("请输入面积"));
        } else if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value != "" && value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };

    var oldmj = (rule, value, callback) => {
        if (this.notRight !=1 && value === "" || value == null) {
            callback(new Error("请输入原面积"));
        }else if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value != "" && value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };

    var changeinfo = (rule, value, callback) => {
      if (this.fallInfo.lzmj!= this.fallInfo.oldmj &&   value === "" ) {
        callback(new Error("请输入面积变化原因"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
        if (value === "" || value == null) {
            callback(new Error("请输入不动产单元号"));
        }else if(value.length != 28 || !/^[a-zA-Z0-9]+$/.test(value)){
            callback(new Error('不动产单元号只能由28位的数字和字母组成'));
        }else {
        callback();
      }
    };
 
    var lzlandtypesnowid = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择土地用途"));
      } else {
        callback();
      }
    };

    // var ishavecertificate = (rule, value, callback) => {
    //   if (value === "" || value == null) {
    //     callback(new Error("请选择有无证（批复）"));
    //   } else {
    //     callback();
    //   }
    // };

    var oldbh= (rule, value, callback) => {
      if (this.notRight!=1 &&  value === "") {
        callback(new Error("请输入证书编号"));
      } else {
        callback();
      }
    };
    return {
        
        sysType:"",
        zydwname:"",
        zydwid:"",
        zyryid:"",

        zydwList:[],
        canDo:true,
        showPagination:false,
        regions:"",
        options:[],
        cityProps:{
            value:'name',   
            label:'name',  
            children: 'children'
        },
        cityPropsB:{
            value:'name',   
            label:'name',  
            children: 'children',
            checkStrictly:true,
        },
        zsbh:"",
        name:"",
        sbdcdyh:"",
        tjr:"",
        tjrList:[],
        sfyz:"",
        mjsfyy:"",
        zdmc:"",
        zslx:"",
        zslxList:[],
        zdlx:"",
        zdlxList:[],

        fejdw:"",
        ejdwList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,



        doTypeStr:"",
        doType:"",
 
        fallModal:false,
        fallInfo:{bdcdyh:""},
        fallrules: {
            LANDNAME: [{ validator: LANDNAME, trigger: "blur" }],
            EJDW: [{ validator: EJDW, trigger: "blur" }],
            zstypesnowid: [{ validator: zstypesnowid, trigger: "blur" }],
            regions: [{ validator: regions, trigger: "blur" }],
            lzmj: [{ validator: lzmj, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            lzlandtypesnowid:[{ validator: lzlandtypesnowid, trigger: "blur" }],
            // ishavecertificate:[{ validator: ishavecertificate, trigger: "blur" }],
            oldbh:[{ validator: oldbh, trigger: "blur" }],
            oldmj:[{ validator: oldmj, trigger: "blur" }],
            changeinfo:[{ validator: changeinfo, trigger: "blur" }],
        },
        notRight:1,

        fallItem:null,
        fallTableHeight:0,
        FpostList:[],
        FPageIndex:1,
        FPageSize:10,
        FTotal:0,
        FallBindModal:false,


        exportModal:false,
        exportEjdw:"",
        exportZslx:"",
        exportTdyt:"",


        checkUnits:[],
        exportTimer:"",
        exportStatus:false,

        exportTimerB:"",
        exportModalB:false,
        exportStatusB:false,
        checkUnitsB:[],

        exportTimerC:"",    
        exportModalC:false,
        exportStatusC:false,
        checkUnitsC:[],
    };
  },

  mounted() {
    this.fallTableHeight = h - 280
    this.sysType=this.$store.state.sysType  
    if(this.sysType=="2"){
        this.getZydwid()
        this.getZYDWList()
        this.zydwname=this.$store.state.name
    }else if(this.sysType=="3"){
        this.zyryid=this.$store.state.snowid  
        this.tjr=this.$store.state.snowid  
        this.getPostList()
    }else if(this.sysType=="5"){
        this.getZYDWList()
        this.getPostList()
    }
    this.options=this.comjs.citys

    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.taskName
        this.zydwid=getQueryData.submitworkunitsnowid
        this.tjr=getQueryData.submitworkusersnowid
        this.zdlx=getQueryData.LZLandTypeSnowID
        this.sfyz=getQueryData.isHaveCertificate
        this.mjsfyy=getQueryData.mjIsInconsistent
        this.zdmc=getQueryData.LandName
        this.zslx=getQueryData.zstypesnowid
        this.fejdw=getQueryData.EJDW
        var regions=[]
        if(getQueryData.region!=''){
            regions=getQueryData.region.split("/")
        }
        this.regions=regions
    }
    this.loadItem=null
    this.getZdlx()
    this.getEjdwList()
    this.getZslxList()
  },
  methods: {
    zslxChange(e){
      this.notRight=1
      if(e!=""){
        for(var i=0;i<this.zslxList.length;i++){
            if(this.zslxList[i].SnowID == e){
                this.notRight=this.zslxList[i].NotRight
                break;
            }
        }
      } 
    },
    getZslxList(){
        this.$http.post("ZSType/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.zslxList=response.data.DATA
                }
            })
    },
    getZYDWList(){
        this.$http.post("WorkUnit/GetDataList", {})
            .then((response) => {
                this.zydwList=response.data.DATA
            })
    },
    getZdlx(){
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getZdlxNameById(id){
        var name=""
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID== id){
                name=this.zdlxList[i].Name
            }
        }
        return name
    },
    getZydwid(){
        this.canDo=false
        this.$http.post("WorkUnit/GetDataList", {name:this.$store.state.name})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].SNOWID
                    this.canDo=true
                    this.checkUnits=[this.zydwid]
                    this.getzzryList(this.zydwid)
                    this.getPostList()
                }else{

                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getzzryList(unitid){
        this.$http.post("WorkUser/GetDataList", {parentSnowID:unitid})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.tjrList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },


    getPostList(){
        this.showLoading("请稍后")
        var region=''
        if(this.regions && this.regions.length>0){
            for(var i=0;i<this.regions.length;i++){
                if(i==0){
                    region=this.regions[i]
                }else{
                    region=region+"/"+this.regions[i]
                }
            }
        }
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            taskName:this.name,
            submitworkunitsnowid:this.zydwid,
            submitworkusersnowid:this.tjr,
            LZLandTypeSnowID:this.zdlx,
            isHaveCertificate:this.sfyz,
            mjIsInconsistent:this.mjsfyy,
            region:region,
            BDCDYH:this.sbdcdyh,
            EJDW:this.fejdw,
            LandName:this.zdmc,
            zstypesnowid:this.zslx,
            OLDBH:this.zsbh,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("LandLZ/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    editPost(d){
        var oldmj=''
        if(d.OLDMJ && d.OLDMJ!=''){
            oldmj=d.OLDMJ
        }
        this.zslxChange(d.ZSTYPESNOWID)
        var sfyz='0'
        var oldbh=''
        if(this.notRight !=1 && d.OLDBH && d.OLDBH!=''){
            oldbh=d.OLDBH
            sfyz='1'
        }

        var changeinfo=''
        if(d.CHANGEINFO && d.CHANGEINFO!=''){
            changeinfo=d.CHANGEINFO
        }

        var x=''
        if(d.TOWN && d.TOWN!=''){
            x=d.TOWN
        }

        var c=''
        if(d.VILLAGE && d.VILLAGE!=''){
            c=d.VILLAGE
        }


       
        this.fallInfo={
            LANDNAME:d.LANDNAME,
            EJDW:d.EJDW,
            TOWN:x,
            VILLAGE:c,
            zstypesnowid:d.ZSTYPESNOWID,
            snowid:d.SnowID,
            regions:d.REGION.split("/"),
            lzmj:d.LZMJ,
            bdcdyh:d.BDCDYH,
            lzlandtypesnowid:d.LZLANDTYPESNOWID,
            ishavecertificate:d.ISHAVECERTIFICATE.toString(),
            oldbh:oldbh,
            oldmj:oldmj,
            changeinfo:changeinfo,
        }
        this.fallModal=true
    },
    submitFallForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    var sfyz='0'
                    var oldbh=''
                    if(this.notRight!=1){
                        oldbh=this.fallInfo.oldbh
                        sfyz='1'
                    }
                    var lzmj=this.comjs.toFixedPrice(this.fallInfo.lzmj)
                    var oldmj=''
                    if(this.fallInfo.oldmj !=''){
                        oldmj=this.comjs.toFixedPrice(this.fallInfo.oldmj)
                    }


                    var info={
                        LANDNAME:this.fallInfo.LANDNAME,
                        EJDW:this.fallInfo.EJDW,
                        TOWN:this.fallInfo.TOWN,
                        VILLAGE:this.fallInfo.VILLAGE,
                        zstypesnowid:this.fallInfo.zstypesnowid,
                        region:this.fallInfo.regions[0]+"/"+this.fallInfo.regions[1]+"/"+this.fallInfo.regions[2],
                        lzmj:lzmj,
                        bdcdyh:this.fallInfo.bdcdyh,
                        lzlandtypesnowid:this.fallInfo.lzlandtypesnowid,
                        ishavecertificate:sfyz,
                        oldbh:oldbh,
                        oldmj:oldmj,
                        changeinfo:this.fallInfo.changeinfo,
                        snowid:this.fallInfo.snowid,
                    }
                    this.affirmFAddModal(info)
                } else {
                    return false;
                }
            });
        }
    },

    affirmFAddModal(info){
        this.$confirm('请确保落宗信息无误在进行提交', '提示', {
                confirmButtonText: '确定提交',
                cancelButtonText: '我再看看',
                type: 'warning'
            }).then(() => {
                this.sendFAdd(info)
            })
    },

    sendFAdd(info){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandLZ/InsertOrUpdateData", info)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fallModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    delFall(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelFall(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelFall(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandLZ/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },

    taskPost(item){
        this.fallItem=item
        this.FpageIndex=1
        this.searchFallBindTaskList()
        this.FallBindModal=true
    },
    searchFallBindTaskList(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            lzSnowID:this.fallItem.SnowID,
            pageIndex:this.FpageIndex,
            pageSize:this.FpageSize,
        }
        this.$http.post("LandLZ/GetLZBindDetail", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.FpostList=response.data.DATA
                    this.FTotal=response.data.TOTALCOUNT
                } else {
                    this.FpostList=response.data.DATA
                    this.FTotal=response.data.TOTALCOUNT
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    FPGChange(e){
        this.FpostList=[]
        this.FPageIndex=e
        this.FTotal=0
        this.searchFallBindTaskList()
    },
    unbindPost(item){
        if(this.canDo){
            this.$confirm('确定解绑？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendUnbind(item.TASKSNOWID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendUnbind(id){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            lzSnowID:this.fallItem.SnowID,
            taskSnowID:id,
        }
        this.$http.post("LandLZ/TaskUnBingLZ", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已解绑")
                    this.searchFallBindTaskList()
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }
            });
    },

    exportPost(){
        this.exportModal=true
    },
    submitExport(){
        if(this.canDo){
            if(this.exportStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatus=false
                    this.exportTimer=new Date().getTime().toString()
                })
               
            }else{
                if(this.checkUnits.length===0){
                    this.$message.error("请至少选择一个作业单位")
                }else{
                    this.exportTimer = new Date().getTime().toString()
                    this.sendExport(this.exportTimer)
                }
            } 
        }
    },
    sendExport(timestr){
        if(this.canDo && timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={
                workUnitSnowIDList:this.checkUnits,
                ejdw:this.exportEjdw,
                oldBHType:this.exportZslx,
                lzLandTypeSnowID:this.exportTdyt,
            }
            this.$download.post("ExportExcel/ExportLZList", params,{responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    this.canDo=true
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName()
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.zip')
                                } else {
                                    window.navigator.msSaveBlob(blob, namenew+".zip")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob)
                                downloadElement.href = href
                                downloadElement.download = namenew+".zip"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                })
                .catch((error) => {
                    if (error.isAxiosError) {
                        if(error.response.status == 403){
                            this.$message.error("此账号暂无导出权限")
                        }
                    }
                    this.exportStatus=false
                });
            }
    },
    exportPostB(){
        this.exportModalB=true
    },
    submitExportB(){
        if(this.canDo){
            if(this.exportStatusB){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatusB=false
                    this.exportTimerB=new Date().getTime().toString()
                })
               
            }else{
                if(this.checkUnitsB.length===0){
                    this.$message.error("请至少选择一个作业单位")
                }else{
                    this.exportTimerB=new Date().getTime().toString()
                    this.sendExportB(this.exportTimerB)
                }
            }
        }
    },
    sendExportB(timestr){
        if(timestr===this.exportTimerB){
            this.canDo=false
            this.exportStatusB=true
            var params={
                workUnitSnowIDList:this.checkUnitsB,
            }
            this.$download.post("ExportExcel/ExportWorkUnitSettlementList", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    this.canDo=true
                    if(this.exportStatusB && timestr===this.exportTimerB){
                        if (resJson) {
                            var namenew=this.getFileNameB()+"结算清单表"
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.zip')
                                } else {
                                    window.navigator.msSaveBlob(blob, namenew+".zip")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".zip"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatusB=false
                })
                .catch((error) => {
                    if (error.isAxiosError) {
                        if(error.response.status==403){
                            this.$message.error("此账号暂无导出权限")
                        }
                    }
                    this.exportStatusB=false
                });
            }
    },

    exportPostC(){
        this.exportModalC=true
    },
    submitExportC(){
        if(this.canDo){
            if(this.exportStatusC){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatusC=false
                    this.exportTimerC=new Date().getTime().toString()
                })
               
            }else{
                if(this.checkUnitsC.length===0){
                    this.$message.error("请至少选择一个作业单位")
                }else{
                    this.exportTimerC=new Date().getTime().toString()
                    this.sendExportC(this.exportTimerC)
                }
            }
            
        }

    },
    sendExportC(timestr){
        if(timestr===this.exportTimerC){
            this.canDo=false
            this.exportStatusC=true
            var params={
                workUnitSnowIDList:this.checkUnitsC,
            }
            this.$download.post("ExportExcel/ExportWorkUnitSettlementCountList", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    this.canDo=true
                    if(this.exportStatusC && timestr===this.exportTimerC){
                        if (resJson) {
                            var namenew=this.getFileNameC()+"难度等级结算表"
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.xlsx')
                                } else {
                                    window.navigator.msSaveBlob(blob, namenew+".xlsx")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".xlsx"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatusC=false
                })
                .catch((error) => {
                    if (error.isAxiosError) {
                        if(error.response.status==403){
                            this.$message.error("此账号暂无导出权限")
                        }
                    }
                    this.exportStatusC=false
                });
            }
    },

    getZydwStrById(id){
        var name=''
        for(var i=0;i<this.zydwList.length;i++){
            if(this.zydwList[i].SNOWID == id){
                name=this.zydwList[i].DISPLAYNAME
                break;
            }
        }
        return name
    },
    getTdytStrById(id){
        var name=''
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID == id){
                name=this.zdlxList[i].Name
                break;
            }
        }
        return name
    },
    getZslxStrById(lx){
        var name=''
        if(lx=='0'){
            name='土地证'
        }else if(lx=='1'){
            name='批复'
        }else if(lx=='2'){
            name='无权源'
        }
        return name
    },
    getFileName(){
        var name=""
        for(var i=0;i<this.checkUnits.length;i++){
            var dwname=this.getZydwStrById(this.checkUnits[i])
            if(i==0){
                name=dwname
            }else{
                name+="_"+dwname
            }
        }



        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t=y.toString()+m.toString()+d.toString()
        name+="_"+t

        var option=''
        if(this.exportEjdw !=''){
            option="("+this.exportEjdw
        }
        if(this.exportTdyt !=''){
            var tdytname=this.getTdytStrById(this.exportTdyt)
            if(option.indexOf("(") != -1){
                option+='、'+tdytname
            }else{
                option="("+tdytname
            }
        }
        if(this.exportZslx!=''){
            var zslxname=this.getZslxStrById(this.exportZslx)
            if(option.indexOf("(") != -1){
                option+='、'+zslxname
            }else{
                option="("+zslxname
            }
        }
        if(option!=''){
            option+=")"
        }
        name+=option+"落宗详情表"


        return name
    },
    getFileNameB(){
        var name=""
        for(var i=0;i<this.checkUnitsB.length;i++){
            var dwname=this.getZydwStrById(this.checkUnitsB[i])
            if(i==0){
                name=dwname
            }else{
                name+="_"+dwname
            }
        }

        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t=y.toString()+m.toString()+d.toString()
        name+="_"+t

        return name
    },
    getFileNameC(){
        var name=""
        for(var i=0;i<this.checkUnitsC.length;i++){
            var dwname=this.getZydwStrById(this.checkUnitsC[i])
            if(i==0){
                name=dwname
            }else{
                name+="_"+dwname
            }
        }

        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t=y.toString()+m.toString()+d.toString()
        name+="_"+t

        return name
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
  .dhylength{font-size:12px;color:#ADADAD;position: absolute;right: 30px;top:0px;}
  .dhyok{color:#67C23A}
</style>
