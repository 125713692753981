import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


import FruitUserManage from '../views/User/FruitUserManage.vue'
import OilUserManage from '../views/User/OilUserManage.vue'

import FruitManage from '../views/Fruit/FruitManage.vue'
import LandTaskManage from '../views/Fruit/LandTaskManage.vue'
import WMS from '../views/Fruit/WMS.vue'
import TaskList from '../views/Fall/TaskList.vue'
import FallList from '../views/Fall/FallList.vue'

import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/FruitUserManage',
        name: 'FruitUserManage',
        components: {FruitUserManage}
      },
      {
        path: '/OilUserManage',
        name: 'OilUserManage',
        components: {OilUserManage}
      },
      {
        path: '/FruitManage',
        name: 'FruitManage',
        components: {FruitManage}
      },
      {
        path: '/LandTaskManage',
        name: 'LandTaskManage',
        components: {LandTaskManage}
      },
      {
        path: '/TaskList',
        name: 'TaskList',
        components: {TaskList}
      },
      {
        path: '/FallList',
        name: 'FallList',
        components: {FallList}
      },
      {
        path: '/WMS',
        name: 'WMS',
        components: {WMS}
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var initRouter= store.state.initRouter
  if(initRouter == ""){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next(true);
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
